import './App.css'
import LandingPage from './LandingPage'
import { useParams } from 'react-router'

function App() {
  const { styleId } = useParams()
  return (
    <LandingPage styleId={styleId}/>
  )
}

export default App
