import app from './firebase'
import React from 'react';
import './index.css';
import App from './App'
import SignUp from './SignUp'

import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import SignIn from './SignIn';

console.log(app)

const ErrorBoundary = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
        <h1>Oops!</h1>
        <p>This page doesn't exist yet</p>
    </div>
);
}
const router = createBrowserRouter([
  {
    path: "/:styleId",
    element: <App />,
    errorElement: <ErrorBoundary />

  },{
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />
  },{
	path: "/sign-up",
	element: <SignUp />,
	errorElement: <ErrorBoundary />
  },{
	path: "/sign-in",
	element: <SignIn />,
	errorElement: <ErrorBoundary />
  }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
