import React, { useEffect, useState } from "react"
import './compiledcss.css'

const D20 = ({color = 'rgba(110, 209, 255, 0.75)'}) => {
    const [activeSide, setActiveSide] = useState(10)

    useEffect(() => {
        setInterval(() => {
            setActiveSide(Math.ceil(Math.random() * 20))
        }, 5000)
    }, [])

    return (<div className="die_container scale-90 sm:scale-[0.6] sm:-translate-y-[40px] translate-x-[50%] sm:translate-x-0">
        <div data-face={activeSide} style={{borderBottomColor: color}}  className="die">
            <figure style={{borderBottomColor: color}} className={`face face-1 ${activeSide === 1 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-2 ${activeSide === 2 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-3 ${activeSide === 3 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-4 ${activeSide === 4 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-5 ${activeSide === 5 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-6 ${activeSide === 6 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-7 ${activeSide === 7 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-8 ${activeSide === 8 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-9 ${activeSide === 9 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-10 ${activeSide === 10  ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-11 ${activeSide === 11 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-12 ${activeSide === 12 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-13 ${activeSide === 13 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-14 ${activeSide === 14 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-15 ${activeSide === 15 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-16 ${activeSide === 16 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-17 ${activeSide === 17 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-18 ${activeSide === 18 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-19 ${activeSide === 19 ? 'active' : ''}`}></figure>
            <figure style={{borderBottomColor: color}} className={`face face-20 ${activeSide === 20 ? 'active' : ''}`}></figure>
        </div>
    </div>
    )
}

export default D20