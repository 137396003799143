import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import D20 from './D20';
import './index.css'

const Banner = ({ styled }) => {
	// need to make banner slightly smaller to show user info?
	const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))

	const handleSignOut = () => {
		localStorage.removeItem("user")
		setUser(null)
	}
	return (
		<div className={`${styled.bannerBackground} flex flex-col sm:flex-row w-full text-[60px] md:text-[120px] h-[140px] leading-[130px] mt-2 align-center justify-between ${styled.bannerTextColor} ${styled.font}`}>
			<div className="text-sm flex-row justify-right">
			</div>
			<div className="text-center h-full relative sm:block flex flex-col-reverse">
				<div className="h-full w-full sm:w-auto absolute sm:ml-0 opacity-50 sm:opacity-100">
					<D20 color={styled.d20 ? styled.d20 : null} />
				</div>
				<p className={`${styled.addclass ? styled.addclass : ''} z-20`}>Gamersburgh</p>
				{user && <div className="text-sm">Signed in as {user.email}</div>}
			</div>
			<div className="text-sm text-right flex-row items-right justify-right">
				{!user && <a href="/sign-up">Sign Up</a>}
				{user && <div className="text-sm cursor-pointer" onClick={handleSignOut}>Sign Out</div>}
			</div>
		</div>
	)
}

const LandingPage = ({ styleId = 0 }) => {
	const [members, setMembers] = useState(Math.floor(Math.random() * 800) + 200)
	const [games, setGames] = useState(Math.floor(Math.random() * 10000) + 1200)

	const styles = [
		{
			// Style 0
			backdrop: 'rgba(0,0,0,0.8)',
			bannerBackground: 'bg-[rgba(0,0,0,0.05)] backdrop-blur-xl',
			sectionBackground: 'bg-sky-900 bg-opacity-40',
			border: 'border border-gray-700',
			textColor: 'text-gray-200',
			bannerTextColor: 'text-sky-100',
			adbackground: 'bg-sky-800 bg-opacity-40',
			font: 'fondamento-regular',
			bodyFont: 'platypi-500'
		},
		{
			// Style 0
			backdrop: 'rgba(0,0,0,0.8)',
			bannerBackground: 'bg-black bg-opacity-70 backdrop-blur-xl',
			sectionBackground: 'bg-sky-900 bg-opacity-40',
			border: 'border border-gray-700',
			textColor: 'text-gray-200',
			bannerTextColor: 'text-sky-100',
			adbackground: 'bg-sky-800 bg-opacity-40',
			font: 'fondamento-regular',
			bodyFont: 'sedan-sc-regular'
		},
		// {
		// 	// Style 2
		// 	backdrop: 'rgba(0,0,0,0.5)',
		// 	bannerBackground: 'bg-black bg-opacity-70 backdrop-blur-xl',
		// 	sectionBackground: 'bg-gray-900 bg-opacity-10',
		// 	border: 'border border-gray-400',
		// 	textColor: 'text-gray-200',
		// 	bannerTextColor: 'text-amber-400',
		// 	adbackground: 'bg-gray-600',
		// 	font: 'jacquard-24-regular',
		// 	d20: 'rgba(128,0,128,0.7)',
		// 	addclass: 'title_no_smooth'
		// },
		// {
		// 	// Style 3
		// 	backdrop: 'rgba(0,0,0,0.5)',
		// 	bannerBackground: 'bg-black bg-opacity-70 backdrop-blur-xl',
		// 	sectionBackground: 'bg-sky-900 bg-opacity-40',
		// 	border: 'border border-gray-700',
		// 	textColor: 'text-sky-200',
		// 	bannerTextColor: 'text-amber-500',
		// 	adbackground: 'bg-sky-800 bg-opacity-40',
		// 	font: 'jaini-regular'
		// },
		// {
		// 	// Style 4
		// 	backdrop: 'rgba(0,0,0,0.8)',
		// 	bannerBackground: 'bg-gray-600 bg-opacity-70 backdrop-blur-xl',
		// 	sectionBackground: 'bg-gray-300 bg-opacity-80',
		// 	border: 'border border-gray-800',
		// 	textColor: 'text-gray-800',
		// 	bannerTextColor: 'text-yellow-300',
		// 	adbackground: 'bg-gray-600',
		// 	font: 'sedan-sc-regular'
		// },
		// {
		// 	// Style 5
		// 	backdrop: 'rgba(255,255,255,1)',
		// 	bannerBackground: 'bg-gray-300 bg-opacity-70 backdrop-blur-xl',
		// 	sectionBackground: 'bg-gray-100 bg-opacity-80',
		// 	border: 'border-none',
		// 	textColor: 'text-gray-800',
		// 	bannerTextColor: 'text-gray-900',
		// 	adbackground: 'bg-gray-200',
		// 	font: 'jacquard-24-regular',
		// 	d20: 'rgba(40, 40, 40, 0.5)',
		// 	addclass: 'title_no_smooth'
		// }
	];
	if (styleId > styles.length) {
		styleId = 0
	}

	const style = styles[styleId];

	const randomMembers = () => {
		setMembers(Math.floor(Math.random() * 800) + 200)
	}

	const randomGames = () => {
		setGames(Math.floor(Math.random() * 800) + 200)
	}

	return (
		<div className="img_background">
			<div style={{ backgroundColor: style.backdrop }} className={`backdrop-blur-sm min-h-screen ${style.textColor}`}>
				{/* Banner */}
				<Banner styled={style} />
				{/* First Section - Three Columns */}
				<div className={`container mx-auto py-8 ${style.bodyFont}`}>
					<div className="flex flex-col sm:flex-row p-4">
						{/* Boardgames Column */}
						<div className={`w-full sm:w-1/3 sm:mx-4 p-4 rounded-md ${style.border} ${style.sectionBackground}`}>
							<h2 className="text-xl underline font-semibold mb-4">Boardgames</h2>
							<ul>
								<li><Link to="/boardgames/regular-home-groups">Regular Home Groups</Link></li>
								<ul className="ml-4">
									<li><Link to="/boardgames/game-clatches">Game Clatches</Link></li>
									<ul className="ml-4">
										<li><Link to="/boardgames/party-social">Party / Social</Link></li>
										<li>
											<Link to="/boardgames/euros">Euro's</Link>
											<ul className="ml-4">
												<li><Link to="/boardgames/euros/light">Light</Link></li>
												<li><Link to="/boardgames/euros/medium">Medium</Link></li>
												<li><Link to="/boardgames/euros/heavy">Heavy</Link></li>
												<li><Link to="/boardgames/euros/chonky">Chonky</Link></li>
											</ul>
										</li>
										<li><Link to="/boardgames/classic">Classic</Link></li>
										<li><Link to="/boardgames/war">War</Link></li>
									</ul>
								</ul>
							</ul>
						</div>

						{/* Role Playing Games Column */}
						<div className={`w-full sm:w-1/3 sm:mx-4 p-4 rounded-md ${style.border} ${style.sectionBackground}`}>
							<h2 className="text-xl underline font-semibold mb-4">Role Playing Games</h2>
							<ul>
								<li><Link to="/rpgs/regular-home-groups">Regular Home Groups</Link></li>
								<ul className="ml-4">
									<li><Link to="/rpgs/dnd">D&D</Link></li>
									<li><Link to="/rpgs/pathfinder">PathFinder</Link></li>
									<li><Link to="/rpgs/alternative">Alternative</Link></li>
									<li><Link to="/rpgs/horror">Horror</Link></li>
									<li><Link to="/rpgs/classic">Classic</Link></li>
									<li><Link to="/rpgs/d20">D20</Link></li>
								</ul>
							</ul>
						</div>

						{/* Video Games Column */}
						<div className={`w-full sm:w-1/3 sm:mx-4 p-4 rounded-md ${style.border} ${style.sectionBackground}`}>
							<h2 className="text-xl underline font-semibold mb-4">Video Games</h2>
							<ul>
								<li><Link to="/video-games/game-tips">Game Tips</Link></li>
								<li><Link to="/video-games/clan-guild-recruitment">Clan / Guild Recruitment</Link></li>
								<li><Link to="/video-games/lan-setups">LAN Setups</Link></li>
								<li><Link to="/video-games/challenges-tourneys">Challenges / Tourneys</Link></li>
								<li><Link to="/video-games/game-trades-exchange">Game Trades / Exchange</Link></li>
							</ul>
						</div>
					</div>
				</div>

				{/* Banner Ad Space */}
				{/* <div className={`${style.adbackground} border-x-0 border-y-4 ${style.border} py-8 text-center`}> */}
				<div className="w-full h-[150px] px-0 sm:px-4 flex sm:flex-row flex-col justify-around items-center">
					<button onClick={randomMembers} className="text-xl text-yellow-400 rounded-sm w-full sm:w-[10%] border-2 border-gray-500 h-1/3 flex items-center justify-center">Members: {members}</button>
					<div className={`w-[80%] h-full rounded-md mx-4 bg-gray-900 border-2 ${style.border} flex items-center justify-center`}>Banner Ad Space</div>
					<button onClick={randomGames} className="text-xl text-yellow-400 rounded-sm w-full sm:w-[10%] border-2 border-gray-500 h-1/3 flex items-center justify-center">Games: {games}</button>
				</div>
				{/* </div> */}

				{/* Second Section - Three Columns */}
				<div className={`container mx-auto py-8 ${style.bodyFont}`}>
					<div className="flex justify-center flex-col sm:flex-row p-4">
						{/* Miniatures Column */}
						<div className={`w-full sm:w-1/3 sm:mx-4 p-4 rounded-md ${style.border} ${style.sectionBackground}`}>
							<h2 className="text-xl underline font-semibold mb-4">Miniatures</h2>
							<ul>
								<li><Link to="/miniatures/warhammer">Warhammer</Link></li>
								<li><Link to="/miniatures/starwars-space">Starwars / Space</Link></li>
								<li><Link to="/miniatures/other">Other</Link></li>
								<li><Link to="/miniatures/painting-tips">Painting Tips</Link></li>
								<li><Link to="/miniatures/painting-services">Painting Services</Link></li>
								<li><Link to="/miniatures/terrain-tips">Terrain Tips</Link></li>
								<li><Link to="/miniatures/3d-printing-terrain-links">3D Printing Terrain Links</Link></li>
								<li><Link to="/miniatures/3d-printing-minis-links">3D Printing Minis Links</Link></li>
							</ul>
						</div>

						{/* Resources / Help Column */}
						<div className={`w-full sm:w-1/3 sm:mx-4 p-4 rounded-md ${style.border} ${style.sectionBackground}`}>
							<h2 className="text-xl underline font-semibold mb-4">Resources / Help</h2>
							<ul>
								<li><Link to="/resources/rpg-world-creation">RPG World Creation</Link></li>
								<li><Link to="/resources/map-making">Map Making</Link></li>
								<li><Link to="/resources/music">Music</Link></li>
								<li><Link to="/resources/npc-substance-embodiment">NPC Substance & Embodiment</Link></li>
								<li><Link to="/resources/artists">Artists</Link></li>
								<li><Link to="/resources/rent-a-gm">Rent a GM</Link></li>
								<li><Link to="/resources/computer-tech-help">Computer / Tech Help</Link></li>
								<li><Link to="/resources/computer-builds-setup">Computer Builds / Setup</Link></li>
							</ul>
						</div>

						{/* News & Events Column */}
						<div className={`w-full sm:w-1/3 sm:mx-4 p-4 rounded-md ${style.border} ${style.sectionBackground}`}>
							<h2 className="text-xl underline font-semibold mb-4">News & Events</h2>
							<ul>
								<li><Link to="/news-events/local-events">Local Events</Link></li>
								<li><Link to="/news-events/conventions">Conventions</Link></li>
								<li><Link to="/news-events/game-breaking-news">Game Breaking News</Link></li>
								<li><Link to="/news-events/game-company-announcements">Game Company Announcements</Link></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="container mx-auto py-8">
					<div className="flex justify-around flex-col sm:flex-row p-4">
						<Link to="/rules">Gamersburgh Rules</Link>
						<Link to="/resources/map-making">Legal</Link>
					</div>
				</div>
			</div>
		</div>

	);
};

export default LandingPage;
