import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const auth = getAuth();

const SignIn = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);

	const navigate = useNavigate();

	const handleSignIn = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password).then(userCredential => {
				localStorage.setItem("user", JSON.stringify(userCredential.user))
			});
			navigate('/');
		} catch (error) {
			setError(error.message);
			console.error('Error signing in:', error);
		}
	};

	return (
		<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 backdrop-blur-md">
			<div className="bg-white p-8 rounded border border-sky-200">
				<h2 className="text-2xl font-bold mb-4">Sign In</h2>
				{error && <p className="text-red-500 mb-4">{error}</p>}
				<div className="mb-4">
					<label htmlFor="email" className="block mb-1">Email</label>
					<input
						type="email"
						id="email"
						className="w-full border border-gray-300 rounded px-3 py-2"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="mb-4">
					<label htmlFor="password" className="block mb-1">Password</label>
					<input
						type="password"
						id="password"
						className="w-full border border-gray-300 rounded px-3 py-2"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<button
					className="w-full bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
					onClick={handleSignIn}
				>
					Sign In
				</button>
				<p className="mt-4 text-center text-gray-600">Don't have an account? <button className="text-blue-500" onClick={() => navigate('/sign-up')}>Sign Up</button></p>
			</div>
		</div>
	);
};

export default SignIn;
